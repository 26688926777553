import api from "./index";

export default {
  async addToCart({
    stockItem,
    quantity = 1,
    isPerfectPartner = false,
    analyticsId = null,
    quickAddIds = null
  } = {}) {
    const response = await api.post("/stock_items/add_to_cart", {
      stock_item_id: stockItem.id,
      qty: quantity,
      is_perfect_partner: isPerfectPartner,
      analytics_id: analyticsId,
      quick_add_ids: quickAddIds
    });
    return response;
  }
};
